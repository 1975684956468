import { methods } from "../enums";
import { apiAction, openAction } from ".";

export const planDataPost = data => ["/plan", methods.POST, data];
export const planDataPut = data => [`/plan/${data._id}`, methods.PUT, data];
export const planBgDataPut = data => ["/plan/update/bg", methods.PUT, data];
export const planMobileBgDataPut = data => ["/plan/update/mobile-bg", methods.PUT, data];
export const planDelete = id => [`/plan/${id}`, methods.DELETE];
export const planDuplicate = id => [`/plan/duplicate/${id}`, methods.POST];
export const planOneGet = id => apiAction(`/plan/one/${id}`);
export const planFilterGet = (search = "", page = 1, limit = 100, sort = "-createdAt", filter = "") => apiAction(
  `/plan/filter?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);
export const planCustomerHidePut = id => [`/plan/customer/hide/${id}`, methods.PUT];
export const planTvPost = body => ["/plan/tv", methods.POST, body];
export const planTvGet = (tv, beginningOfTheDay, endOfTheDay) => apiAction(`/plan/tv/${tv}?start=${beginningOfTheDay}&end=${endOfTheDay}`);
export const planTvDeleteAll = () => apiAction("/plan/tv/delete-all", methods.DELETE);
export const stripePlanPaymentLink = (planId, customerId = "new", locale = "en") => openAction(`/plan/payment-link/${planId}/${customerId}/${locale}`);
