import {
  Box, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";

import {
  GenericError, Loading,
} from "../../components/shared/other";
import { planTvGet } from "../../services/api/plan";
import { intervals } from "../../services/enums";
import { assetUrl } from "../../services/routes";
import { setDateByInterval } from "../../services/utils";
import BlackBox from "./_black-box";

export default ({ match }) => {
  const [entries, setEntries] = React.useState([]);
  const {
    loading, payload, error, status,
  } = useQuery(planTvGet(
    match.params.tv,
    setDateByInterval(intervals.DAILY, 0, new Date(), 0).toISOString(),
    setDateByInterval(intervals.DAILY, 1, new Date(), 0).toISOString(),

  ));

  React.useLayoutEffect(() => {
    if (!loading && !error && !_.isNil(payload)) {
      setEntries(payload?.data);
    }
  }, [payload]);

  if (error) {
    return <BlackBox><GenericError status={status} /></BlackBox>;
  }

  if (loading) {
    return <BlackBox><Loading /></BlackBox>;
  }

  return (
    <BlackBox>
      <Box>
        <img
          src={assetUrl("logo_282x130_white.png")}
          alt="LOGO"
          style={{ maxWidth: 150 }}
        />
      </Box>
      <Box display="flex" width="100%" justifyContent="center" alignItems="baseline">
        {entries.map(entry => (
          <Box whiteSpace="pre-line" margin="30px">
            <Typography variant="h1" style={{ wordBreak: "break-word" }}>{entry.title}</Typography>
            <Typography variant="h2" style={{ wordBreak: "break-word" }}>{entry.content}</Typography>
          </Box>
        ))}
      </Box>
    </BlackBox>
  );
};
